import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { catchError, map } from 'rxjs/operators';
import { MessageService } from './message.service';
import { ParamConsulta } from '@generals';

@Injectable()
export class NFEntradaService extends BaseService {

  constructor(private http: HttpClient, private message: MessageService) { super(); }

  imprimirNfe(nfeId: number) {
    return this.http
      .post(this.UrlService + 'notasFiscaisEntrada/imprimirNfe', nfeId, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  carregarDadosNFes(notasFiscais: number[]) {
    return this.http
      .post(this.UrlService + 'notasFiscaisEntrada/obterNotasRetornoDevolucao', notasFiscais)
      .pipe(map((res: any) => res.data),
        catchError(super.serviceError));
  }

  verificaOrdenacaoItens(nfeID: number) {
    return this.http.get(this.UrlService + `notasFiscaisEntrada/VerificarOrdenacaoItens/${nfeID}`)
      .pipe(map(super.extractData), catchError(super.serviceError));
  }

  buscarItensFornecedores(nfeId) {
    return this.http.get(this.UrlService + `notasFiscaisEntrada/getItensFornecedores/${nfeId}`)
      .pipe(map(super.extractData), catchError(super.serviceError));
  }

  getDadosEdicaoCfopNotaEntrada(notaFiscalEntradaID: number) {
    return this.http.get(this.UrlService + `notasFiscaisEntrada/DadosEdicaoCfopNotaEntrada/${notaFiscalEntradaID}`)
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }

  editarCfopNotaEntrada(notaFiscalEntradaID, dados) {
    return this.http.post(this.UrlService + `notasFiscaisEntrada/EditarCfopNotaEntrada/${notaFiscalEntradaID}`, dados)
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }

  listarNotasFiscaisVendaAOrdem(consulta, fornecedor) {
    const url = this.UrlService
      + 'notasFiscaisEntrada/ListarNFVendaAOrdem'
      + (fornecedor ? `?fornecedor=${fornecedor}` : '');

    return this.http.post(url, consulta)
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }

  montarItensNFeVendaAOrdem(notaEntradaId) {
    return this.http
      .post(this.UrlService + 'notasFiscaisEntrada/montarItensNFeVendaAOrdem', notaEntradaId).pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  buscarNotasEntrada(search: string, paramConsulta: ParamConsulta) {
    return this.http
      .post(this.UrlService + 'notasFiscaisEntrada/buscarNotasEntrada', { search, paramConsulta })
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }

  buscarItensNotasEntrada(notasIds: number[]) {
    return this.http
      .post(this.UrlService + 'notasFiscaisEntrada/buscarItensNotasEntrada', notasIds)
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }

  importarItensNotaEntrada(nfeItensIds: number[]) {
    return this.http
      .post(this.UrlService + 'notasFiscaisEntrada/importarItensNotasEntrada', nfeItensIds)
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }

  buscarNotasEntradaParaRetorno(search: string, paramConsulta: ParamConsulta, itensJaInseridos: number[]) {
    return this.http
      .post(this.UrlService + 'notasFiscaisEntrada/buscarNotasEntradaParaRetorno', { search, paramConsulta, itensJaInseridos })
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }

  buscarDadosModalManifesto(nfeId: number) {
    return this.http
      .get(this.UrlService + `notasFiscaisEntrada/buscarDadosModalManifesto/${nfeId}`)
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }

  buscarDadosModalTitulosFornecedores(nfeId: number) {
    return this.http
      .get(this.UrlService + `notasFiscaisEntrada/buscarDadosModalTitulosFornecedores/${nfeId}`)
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }
}
