import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { getErrorMessage } from '@utils';
import { debounceTime, firstValueFrom } from 'rxjs';
import { ParamConsulta } from '../../../general/models/paramConsulta';
import { MessageService } from '../../../services/message.service';
import { NFEntradaService } from '../../../services/nfentrada.service';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { TempModalCadastroComponent } from '../../../shared/templates/temp-modal-cadastro/temp-modal-cadastro.component';

@Component({
  selector: 'app-retorno-importar-nfe',
  templateUrl: './retorno-importar-nfe.component.html',
  styleUrls: ['./retorno-importar-nfe.component.css'],
})
export class RetornoImportarNfeComponent extends ModalCadastroComponent {
  @ViewChild('modalConsultar', { static: true }) modalConsultar: TempModalCadastroComponent;
  notas: any = {};
  notasSelecionadas = [];
  itens = [];
  itensSelecionados = [];
  paramConsulta = new ParamConsulta();
  form: FormGroup;
  formBuscaItens: FormGroup;
  mostrarItens = false;
  loading = {
    notas: false,
    itens: false,
    importarItens: false,
  };

  colunas = [
    { label: 'Série', col: 'serie' },
    { label: 'Número', col: 'numeroNotaFiscal' },
    { label: 'Data Emissão', col: 'emissao', date: true },
    { label: 'Remetente', col: 'nome' },
    { label: 'Valor', col: 'valorTotal', currency: true },
  ];
  colunasItens = [
    { label: 'Nº Nota', col: 'notaFiscalEntrada.numeroNotaFiscal', invisible: true },
    { label: 'Cód. Entrada', col: 'itemFornecedor.referenciaItem' },
    { label: 'Nome', col: 'itemFornecedor.nome' },
    { label: 'Qtd. Entr.', col: 'quantOriginalEntrada', format: (x: number) => this.formatNumber(x) },
    { label: 'Saldo', col: 'quantidade', format: (x: number) => this.formatNumber(x) },
    { label: 'Preço', col: 'preco', currency: true },
  ];

  itensJaInseridos: number[] = [];
  resultado: any;

  constructor(
    private nfEntradaService: NFEntradaService,
    private messageService: MessageService
  ) {
    super();

    this.form = new FormGroup({
      search: new FormControl(''),
    });

    this.form.get('search').valueChanges.pipe(debounceTime(800)).subscribe(() => {
      if (!this.loading.notas) {
        this.carregarNotas();
      }
    });

    this.formBuscaItens = new FormGroup({
      search: new FormControl(''),
    });
  }

  get itensFiltrados() {
    const filtro = this.formBuscaItens.get('search').value.toLowerCase();
    return filtro
      ? this.itens.filter(x =>
        x.notaFiscalEntrada.numeroNotaFiscal?.toString() === filtro
        || x.itemFornecedor.referenciaItem.toLowerCase().indexOf(filtro) > -1
        || x.itemFornecedor.nome.toLowerCase().indexOf(filtro) > -1)
      : this.itens;
  }

  formatNumber(val) {
    return (val || 0).toString().replace('.', ',');
  }

  show() {
    this.itensJaInseridos = this.prop.itensJaInseridos;
    this.modalConsultar.show();
    this.carregarNotas();
  }

  onSort(paramConsulta) {
    this.paramConsulta = paramConsulta;
    this.carregarNotas();
  }

  async carregarNotas() {
    try {
      this.loading.notas = true;
      const search = this.form.get('search').value;
      this.notas = await firstValueFrom(this.nfEntradaService.buscarNotasEntradaParaRetorno(search, this.paramConsulta, this.itensJaInseridos));
    } catch (error) {
      console.error(error);
      this.messageService.error('Falha ao carregar notas: ' + getErrorMessage(error), 8000);
    } finally {
      this.loading.notas = false;
    }
  }

  async carregarItens() {
    try {
      this.colunasItens.find(x => x.col === 'notaFiscalEntrada.numeroNotaFiscal').invisible = this.notasSelecionadas.length <= 1;
      
      this.mostrarItens = true;
      this.loading.itens = true;

      const ids = this.notasSelecionadas.map(x => x.id);
      const res = await this.nfEntradaService.carregarDadosNFes(ids).toPromise();
      this.resultado = res;
      this.itens = res.itens.filter(x => !this.itensJaInseridos.includes(x.id));
    } catch (err) {
      console.error(err);
      this.messageService.error(getErrorMessage(err), 8000);
    } finally {
      this.loading.itens = false;
    }
  }

  async importarItens() {
    this.closed.emit({
      ...this.resultado,
      itens: this.itensSelecionados,
    });
  }

  voltar() {
    this.itensSelecionados = [];
    this.mostrarItens = false;
  }
}
