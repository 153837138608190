import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { FormGroup, FormControl } from '@angular/forms';
import { ParamConsulta } from '../../../general/models/paramConsulta';
import { UsuariosService } from '../../../services/usuarios.service';
import { AdminService } from '../../../admin/services/admin.service';
import { MessageService } from '@services';
import { getErrorMessage } from '@utils';

@Component({
  selector: 'app-usuarios-empresa',
  templateUrl: './usuarios-empresa.component.html',
  styleUrls: ['./usuarios-empresa.component.css']
})
export class UsuariosEmpresaComponent extends ModalCadastroComponent implements OnInit {

  constructor(
    private usuariosService: UsuariosService,
    private adminService: AdminService,
    private messageService: MessageService,
  ) {
    super();
  }

  @ViewChild('modalConsultar', { static: true }) modalConsultar;
  @ViewChild('abrirModal', { static: false }) abrirModal;
  retornoUsuarios: any = null;
  consultaFormUsuarios: FormGroup;
  colunasUsuarios = [
    { label: '#', col: 'id', hide: false, type: 'button', contentClass: 'ti ti-pencil' },
    { label: 'Login', col: 'login', hide: false, clipboard: true },
    { label: 'Senha', col: 'senha', hide: false, clipboard: true },
  ];

  formImportador = new FormGroup({
    cnpj: new FormControl(null),
    senha: new FormControl(null),
  })

  ngOnInit() {
  }

  show(unidadeEmpresaID) {
    this.id = unidadeEmpresaID;
    this.consultaUsuariosEmpresa(unidadeEmpresaID);
    this.consultaUsuarioImportador(unidadeEmpresaID);
    this.modalConsultar.show();
  }

  editarUsuario(event) {
    this.abrirModal.editar('editarUsuarioClientes', event);
  }

  setPageUsuarios(paramConsulta) {
    this.consultaFormUsuarios.controls['paramConsulta'].setValue(paramConsulta);
    this.doConsultaUsuariosEmpresa();
  }

  onSortUsuarios(paramConsulta) {
    this.consultaFormUsuarios.controls['paramConsulta'].setValue(paramConsulta);
    this.doConsultaUsuariosEmpresa();
  }

  consultaUsuariosEmpresa(unidadeEmpresaID) {
    this.retornoUsuarios = [];
    this.id = unidadeEmpresaID;

    this.consultaFormUsuarios = new FormGroup({
      unidadeEmpresaID: new FormControl(this.id),
      paramConsulta: new FormControl(new ParamConsulta())
    });

    this.doConsultaUsuariosEmpresa();
  }

  doConsultaUsuariosEmpresa() {
    this.usuariosService.getUsuariosPorUnidadeEmpresa(this.consultaFormUsuarios.value)
      .subscribe(res => {
        this.retornoUsuarios = res;
      });
  }

  verificaUserAdmin() {
    return (user) => {
      if (user && user.administrador) {
        return { 'background-color': 'var(--tr-user-admin-bg-color)' };
      }

      return '';
    };
  }
  
  consultaUsuarioImportador(unidadeEmpresaID) {
    this.adminService.buscaUsuarioImportador(unidadeEmpresaID)
      .subscribe(res => {
        this.formImportador.get('cnpj').setValue(res?.cnpj);
        this.formImportador.get('senha').setValue(res?.senha);
        this.formImportador.disable();
      });
  }

  editarSenhaImportador() {
    this.formImportador.get('senha').enable();
  }

  salvarSenhaImportador() {
    this.loading['salvarSenhaImportador'] = true;
    
    this.adminService.alterarSenhaImportador(this.id, this.formImportador.get('senha').value).subscribe(res => {
      this.loading['salvarSenhaImportador'] = false;
      this.formImportador.get('senha').disable();
      this.messageService.success('Senha do importador alterada.', 5000);
    }, err => {
      this.messageService.error(getErrorMessage(err), 8000, 'Falha, tente novamente em alguns instantes.');
      this.loading['salvarSenhaImportador'] = false;
    })
  }
}
