<ng-select (keydown.enter)="clientes.length == 0 && selecionaClienteSemCadastro(select.searchTerm)"
  [disabled]="disabled" #select [items]="clientes" (ngModelChange)="onChange($event)" [ngModel]="cliente"
  placeholder="Selecione" [virtualScroll]="true" (close)="onClose()"
  (scrollToEnd)="fetchMoreCliente(select.searchTerm)" [loading]="loading['clientes']" [typeahead]="clienteInput">

  <ng-template ng-option-tmp let-item="item">
    <div style="display: flex; align-items: center">
      <span class="ng-option-label"
        style="overflow: hidden; width: 100%; text-overflow: ellipsis">{{descricaoCliente(item)}}</span>
      <i *ngIf="item['id']" (click)="select.close(); subscribeModal(); abrirModal.editar('cliente', item.id)"
        style="float: right; font-size: 17px; color: #3F88C5" class="fa fa-edit"></i>

      <i *ngIf="!item['id']" (click)="select.close(); subscribeModal(); abrirModal.cadastrar('cliente', item['cnpj'])"
        style="float: right; cursor: pointer; font-size: 17px; color: #3F88C5" class="fa fa-plus"></i>
    </div>
  </ng-template>

  <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
    <div class="ng-option" [class.error-cpf-cnpj]="errors['cpfCnpj']">
      <div style="display: flex; align-items: center">
        <span class="ng-option-label" (click)="selecionaClienteSemCadastro(searchTerm)"
          style="overflow: hidden; width: 100%; text-overflow: ellipsis">{{searchTerm}} - CPF/CNPJ
          {{mensagemCpfCnpj}}</span>
        <i (click)="select.close(); subscribeModal(); abrirModal.cadastrar('cliente', searchTerm)"
          style="float: right; cursor: pointer; font-size: 17px; color: #3F88C5" class="fa fa-plus"></i>
      </div>
    </div>
  </ng-template>

  <ng-template ng-label-tmp let-item="item">
    <span class="ng-value-label">{{descricaoCliente(item)}}</span>
  </ng-template>


</ng-select>

<app-abrir-modal #abrirModal></app-abrir-modal>
