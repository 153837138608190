<label *ngIf="showLabel" [class.label-required]="campoObrigatorio"
  [ngClass]="classNfe" style="margin-bottom: .5rem;">
  {{getLabel()}}
  <div ngbDropdown container="body" class=" btn-group dropdown-split-primary" style="margin-right: 6px;
  display: inline-block;
  margin-top: 0px;" *ngIf="mostraSelectOptions()">
    <button type="button" ngbDropdownToggle
      class="btn btn-mini btn-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light"
      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="margin: 0px;">
    </button>
    <div ngbDropdownMenu placement="bottom-right" class="dropdown-menu">
      <a class="dropdown-item waves-effect waves-light"
        *ngFor="let op of comboInputs[type].selectOptions.options; index as index"
        (click)="alteraSelectOptions($event, index)">
        <i class="cr-icon icofont icofont-ui-check txt-default" style="font-size: 8px; margin-left: -13px!important;"
          *ngIf="verificaSelectOptionMarcado(op, index)"></i>{{op.value}}
      </a>
    </div>
  </div>
  <i *ngIf="toolTipMessage" class="fa fa-question-circle" style="cursor: pointer" [ngbTooltip]="toolTipMessage"></i>
</label>
<div class="checkbox-fade fade-in-default pull-right" *ngIf="mostraCheckBox()">
  <label class="removeMargin">
    <input type="checkbox" (click)="alteraCheckBox($event)">
    <span class="cr" style="margin-right: 3px; margin-top: 3px; height: 15px; width: 15px;">
      <i class="cr-icon icofont icofont-ui-check txt-default" style="font-size: 8px;"></i>
    </span>
    <span style="font-size: 12px;">{{comboInputs[type].checkbox.text}}</span>
  </label>
</div>

<ng-select #select [appendTo]="appendTo" (change)="onChange($event)" (body:keydown)="pressBackspace($event)"
  [multiple]="multiple"
  [closeOnSelect]="closeOnSelect"
  [disableControl]="disableControl || loading['disable'] || loading['request'] || (!!comboInputs[type].desabilitarAoCarregar && loading[formControlName]) || (form?.controls[formControlName] ? form.controls[formControlName].disabled : false) || loading['id'] || readonly"
  [loading]="loading[formControlName] || loading['id']" [placeholder]="placeholder" [items]="itens" bindValue="id"
  [virtualScroll]="true" (scrollToEnd)="comboInputs[type].search(select.searchTerm || '')" [typeahead]="inputOnSearch"
  [(ngModel)]="value" [autofocus]='autofocus'>
  <ng-template ng-option-tmp let-item="item" *ngIf="abreModalCadastro() && !multiple">
    <app-temp-option-item [name]="itemDescription(item)" [tooltip]="tooltip ? itemDescription(item) : null"
      (onClick)="blurSelect(); subscribeModal(); abrirModal.editar(this.comboInputs[this.type].modalCadastro.name, item.id, this.parametro)">
    </app-temp-option-item>
  </ng-template>

  <ng-template ng-notfound-tmp let-searchTerm="searchTerm" *ngIf="abreModalCadastro() && !multiple">
    <app-temp-not-found-item [searchTerm]="searchTerm"
      (onClick)="select.close(); blurSelect(); subscribeModal(); abrirModal.cadastrar(this.comboInputs[this.type].modalCadastro.name, searchTerm, this.parametro)">
    </app-temp-not-found-item>
  </ng-template>

  <ng-template ng-header-tmp *ngIf="multiple">
    <button (click)="selectAll()" type="button" class="btn btn-sm btn-secondary" style="cursor: pointer">Todos</button>
    <button (click)="clean()" type="button" class="btn btn-sm btn-secondary" style="cursor: pointer">Nenhum</button>
  </ng-template>

  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="multiple">
    <div class="ng-value" *ngFor="let item of (items && items.length <= 3 ? items.slice(0,3): [])">
      <span class="ng-value-label"> {{getItemName(item)}}</span>
      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
    </div>

    <div class="ng-value" *ngIf="items.length > 3">
      <span *ngIf="items.length == itens.length; else selecionados" class="ng-value-label">Todos
        selecionados</span>
      <ng-template #selecionados>
        <span class="ng-value-label">{{items.length}} selecionados</span>
      </ng-template>
    </div>
  </ng-template>

  <ng-container *ngIf="descTmpl != null && descTmpl">
    <ng-template ng-option-tmp let-item="item">
      <ng-template ng *ngTemplateOutlet="descTmpl; context: {row: item}" class="ng-value-label"></ng-template>
    </ng-template>

    <ng-template ng-label-tmp let-item="item">
      <ng-template ng *ngTemplateOutlet="descTmpl; context: {row: item}" class="ng-value-label"></ng-template>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="!(descTmpl != null && descTmpl)">
    <ng-template  ng-option-tmp let-item="item">
      <div container="body" #t="ngbTooltip" [autoClose]="true" triggers="manual" (touchstart)="t.open()" (touchend)="t.close()"
        (mouseover)="t.open()" (mouseleave)="t.close()" [ngbTooltip]="tooltip ? itemDescription(item) : null">
        <span class="ng-value-label">{{itemDescription(item)}}</span>
      </div>
    </ng-template>

    <ng-template ng-label-tmp let-item="item">
      <span #t="ngbTooltip" [autoClose]="true" triggers="manual" (touchstart)="t.open()" (touchend)="t.close()"
        (mouseover)="t.open()" (mouseleave)="t.close()" [ngbTooltip]="tooltip ? itemDescription(item) : null"
        class="ng-value-label">{{itemDescription(item)}}</span>
    </ng-template>
  </ng-container>

</ng-select>
<show-errors *ngIf="existsForm" [control]="form.controls[formControlName]"></show-errors>
<app-abrir-modal #abrirModal></app-abrir-modal>
