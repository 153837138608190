import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ClassificacoesFiscaisService } from '../../../services/classificacoes-fiscais.service';
import { ItensService } from '../../../services/itens.service';
import { MessageService } from '../../../services/message.service';
import * as $ from 'jquery';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-ncm-cloud',
  templateUrl: './ncm-cloud.component.html',
  styleUrls: ['./ncm-cloud.component.css']
})
export class NCMCloudComponent extends ModalCadastroComponent implements OnInit {
  constructor(
    private builder: FormBuilder,
    private message: MessageService,
    private itensService: ItensService,
    private classificacoesService: ClassificacoesFiscaisService,
  ) {
    super();
  }

  @ViewChild('modalConsultar', { static: true }) modalConsultar;
  @ViewChild('abrirModal', { static: false }) abrirModal;

  retornoUsuarios: any = null;
  consultaFormUsuarios: FormGroup;
  public dados: any;
  public dadosDetalhes: any = [];
  public loading = [];
  public rowClassificacao: number = null;
  public searchTerm: string = '';
  public showToTop: boolean = false;
  lastElement: HTMLElement = null;
  obterClassificacoesForm: FormGroup;
  colunas = [
    { label: 'NCM', col: 'classificacaoFiscal', hide: false },
    { label: 'Ocorrências', col: 'ocorrencia', hide: false },
  ];
  colunasDetalhes = [
    { label: 'Produtos', col: 'nome', hide: false }
  ];

  ngOnInit() {
    this.obterClassificacoesForm = this.builder.group({
      wordFilter: new FormControl('', [Validators.required]),
    });
  }

  show() {
    this.modalConsultar.show();
  }

  buscarClassificacoes() {
    if (this.obterClassificacoesForm.value['wordFilter'] !== '') {
      this.loading['classificacoes'] = true;
      this.searchTerm = this.obterClassificacoesForm.value['wordFilter'];
      this.dadosDetalhes = [];
      const loadToast = this.message.addToast({
        title: 'Essa busca pode demorar alguns segundos.',
        msg: 'Aguarde...',
        showClose: true, timeout: 0, theme: 'bootstrap', closeOther: false, type: 'wait'
      });

      this.classificacoesService.GetByNCM(this.obterClassificacoesForm.value['wordFilter'])
        .pipe(finalize(() => {
          this.message.clear(loadToast);
          this.loading['classificacoes'] = false
        }))
        .subscribe(response => {
          this.dados = response;
        });
    } else {
      this.message.error('Adicione uma palavra-chave para a pesquisa');
    }
  }

  detalhar(id, e) {
    this.dadosDetalhes = [];
    this.loading['classificacoesDetalhes'] = true;
    if (this.lastElement) {
      this.lastElement.classList.remove('selectedRow');
      this.lastElement.className = this.lastElement.className.replace(/\bselectedRow\b/g, '');
    }

    const detalhaToast = this.message.addToast({
      title: 'Detalhar pode demorar alguns segundos.',
      msg: 'Aguarde...',
      showClose: true, timeout: 0, theme: 'bootstrap', closeOther: false, type: 'wait'
    });

    this.itensService.GetByNCMId(id, this.searchTerm)
      .pipe(finalize(() => {
        this.message.clear(detalhaToast);
        this.loading['classificacoesDetalhes'] = false
      }))
      .subscribe(response => {
        this.rowClassificacao = id;
        this.dadosDetalhes = response;

        setTimeout(() => {
          const tabelaDetalhes = <HTMLElement>document.querySelector(`#tabelaDetalhes`);
          $('.modal-body').animate({ scrollTop: tabelaDetalhes.offsetTop }, 1000);
          if (e.target.className === 'fa fa-plus') {
            e.target.parentElement.setAttribute('disabled', '');
            e.target.parentElement.parentElement.parentElement.classList.add('selectedRow');
            const arr = e.target.parentElement.parentElement.parentElement.className.split(' ');
            if (arr.indexOf(name) === -1) {
              e.target.parentElement.parentElement.parentElement.className += ' ' + 'selectedRow';
            }
            this.lastElement = e.target.parentElement.parentElement.parentElement;
          } else {
            e.target.setAttribute('disabled', '');
            e.target.parentElement.parentElement.classList.add('selectedRow');
            const arr = e.target.parentElement.parentElement.className.split(' ');
            if (arr.indexOf(name) === -1) {
              e.target.parentElement.parentElement.className += ' ' + 'selectedRow';
            }
            this.lastElement = e.target.parentElement.parentElement;
          }
        }, 100);
      });
  }

  utilizar(id: number) {
    this.id = id;
    this.modalConsultar.hide();
    this.closed.emit(this.id);
  }

  toTop() {
    const tabelaNCMs = <HTMLElement>document.querySelector(`#tabelaNCMs`);
    $('.modal-body').animate({ scrollTop: tabelaNCMs.offsetTop }, 1000);
  }

  onScroll() {
    const tabelaDetalhes = <HTMLElement>document.querySelector(`#tabelaDetalhes`);
    if (tabelaDetalhes) {
      if (document.querySelector('.modal-body').scrollTop >= (tabelaDetalhes.offsetTop / 3)) {
        this.showToTop = true;
      } else {
        this.showToTop = false;
      }
    }
  }
}
