import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../../shared/modal-cadastro/modal-cadastro.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NfConsumidorService } from '../../../../services/nf-consumidor.service';
import { MessageService } from '@services';
import * as moment from 'moment';
import { ModalBasicComponent } from '../../../../shared/modal-basic/modal-basic.component';
import * as saveAs from 'file-saver';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-relatorio-entradas-saidas',
  templateUrl: './relatorio-entradas-saidas.component.html',
  styleUrls: ['./relatorio-entradas-saidas.component.css']
})
export class RelatorioEntradasSaidasComponent extends ModalCadastroComponent implements OnInit { 

    impressaoForm: FormGroup;
    loading = false;
    @ViewChild('modalRelatorio', { static: true }) modalRelatorio: ModalBasicComponent;

    constructor(private nfConsumidorService: NfConsumidorService, private message: MessageService) { super() }

    ngOnInit() {
       this.impressaoForm = new FormGroup({
      periodo1: new FormControl(moment().startOf('month').toDate(), [Validators.required]),
      periodo2: new FormControl(moment().endOf('month').startOf('day').toDate(), [Validators.required]),
      })
    }

    destroyComponent() {
      this.closed.emit();
    }
  
    show(): void {
      this.modalRelatorio.show();
    }


    async submitForm(){
      if(!this.impressaoForm.valid){
        return;
    
      }

      this.loading = true;
      this.modalRelatorio.hide();

      try{
        const res = await lastValueFrom(this.nfConsumidorService.emitirEntradasESaidas(this.impressaoForm.value));
        saveAs(res, 'RelatorioEntradasSaidas.pdf');
      } catch (error) {
        console.error(error);
        this.message.addError({
          title: 'Falha ao gerar relatório.',
          msg: 'Nenhum registro foi encontrado na data informada',
          showClose: true,
          timeout: 20000,
          theme: 'bootstrap',
          closeOther: true
        });
      } finally {
        this.loading = false;
        this.destroyComponent();
      }
    }
}
