import { Component, forwardRef, Input, OnChanges, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SituacaoTributariaICMS, ListaTipoSituacaoTributariaICMS } from '@models';

@Component({
  selector: 'app-combobox-situacao-tributaria-icms',
  templateUrl: './combobox-situacao-tributaria-icms.component.html',
  styleUrls: ['./combobox-situacao-tributaria-icms.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComboboxSituacaoTributariaIcmsComponent),
      multi: true
    }
  ]
})
export class ComboboxSituacaoTributariaIcmsComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input() appendTo = 'body';
  @Input() required = false;
  @Input() loading = false;
  @Input() label = 'CST/CSOSN ICMS';
  @Input() placeholder = 'Selecione';
  @Input() regimes = ['simples', 'normal'];
  @Input() disabled = false;

  stSelected = new FormControl<string>(null);
  onChange: (tipo: string) => string;
  onTouched: () => void;

  situacaoTributariaICMS: SituacaoTributariaICMS[];

  ngOnInit() {
    this.situacaoTributariaICMS = ListaTipoSituacaoTributariaICMS.reduce((initialValue, st) => {
      if (this.regimes.includes(st.regime)) {
        initialValue.push(st);
      }
      return initialValue;
    }, [] as SituacaoTributariaICMS[]);

    if (this.disabled) {
      this.stSelected.disable();
    }
  }

  ngOnChanges(changes) {
    if (changes.disabled) {
      if (changes.disabled.currentValue) {
        this.stSelected.disable();
      } else {
        this.stSelected.enable();
      }
    }
  }

  writeValue(st: string): void {
    this.stSelected.setValue(st);
  }

  registerOnChange(fn: (tipo: string) => string): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onComboboxChange(st: SituacaoTributariaICMS): void {
    this.stSelected.setValue(st?.tipo);
    this.onChange(st?.tipo);
    this.onTouched();
  }
}
