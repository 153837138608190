<app-modal-basic #modal modalContentId="modal-lancamento" (closed)="handleOnClose()">
  <div class="app-modal-header">
    <h4 class="modal-title">
      <i class="modal-title icofont icofont-papers m-r-5"></i>
      Lançamento de Contas a Pagar
    </h4>
    <button style="cursor: pointer;" type="button" class="close basic-close" (click)="handleOnCancel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="app-modal-body">
    <div class="row mb-4">
      <div class="col-12">
        <span style="font-size: 0.85rem;"> 
          <b>Nota Fiscal Série:</b> {{nfEntrada?.serie}} |
          <b>Número:</b> {{nfEntrada?.numeroNotaFiscal}} |
          <b>Valor da Fatura:</b> {{nfEntrada?.valorFatura | currency:'BRL':'symbol':'1.2-2'}}
        </span>
      </div>
    </div>

    <div class="row">
      <form [formGroup]="form" class="w-100">
        <div class="form-group col-12">
          <label class="label-required" for="form_grupoDespesaId">Grupo de Despesa</label>
          <ng-select id="form_grupoDespesaId" appendTo="#modal-lancamento" placeholder="Selecione" [items]="gruposDespesas" 
            bindLabel="descricao" bindValue="id" formControlName="grupoDespesaId">
          </ng-select>
          <show-errors [control]="form.controls.grupoDespesaId"></show-errors>
        </div>

        <div class="form-group col-12">
          <label class="label-required" for="form_contaPagadoraId">Conta Corrente</label>
          <ng-select id="form_contaPagadoraId" appendTo="#modal-lancamento" placeholder="Selecione" [items]="contas" 
            bindLabel="descricao" bindValue="id" formControlName="contaPagadoraId">
          </ng-select>
          <show-errors [control]="form.controls.contaPagadoraId"></show-errors>
        </div>

        <div class="form-group col-12" rem5 combobox [type]="'forma-pagamento-portador'" [loading]="loading"
          [form]="form" formControlName="portadorId" appendTo="#modal-lancamento">
          <show-errors [control]="form.controls.portadorId"></show-errors>
        </div>

        <div class="form-group col-12" rem5 combobox [type]="'centroCustos'" [loading]="loading"
          [form]="form" formControlName="centroCustoId" appendTo="#modal-lancamento">
          <show-errors [control]="form.controls.centroCustoId"></show-errors>
        </div>
      </form>
    </div>

    <div class="row">
      <div class="col-12">
        <button type="button" class="btn btn-primary ripple light" (click)="handleOnSave()" [disabled]="loading['request']">
          <app-loading [loading]="loading['request']" [icone]="'fa fa-save'" texto="Lançar"></app-loading>
        </button>

        <button type="button" class="btn btn-danger ripple" (click)="handleOnCancel()" [disabled]="loading['request']">
          <i class="fa fa-close"></i> Cancelar
        </button>
      </div>
    </div>
  </div>

  <div class="app-modal-footer"></div>
</app-modal-basic>