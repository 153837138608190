import { Component, OnInit, ViewChild } from '@angular/core';
import { ItensService } from '../../../services/itens.service';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TipoSituacaoTributariaIPI } from '../../../model/TipoSituacaoTributariaIPI';
import { TipoSituacaoTributariaPIS } from '../../../model/TipoSituacaoTributariaPIS';
import { TipoSituacaoTributariaCOFINS } from '../../../model/TipoSituacaoTributariaCOFINS';
import { CodigosFiscaisService } from '../../../services/codigos-fiscais.service';
import { ListaTipoSituacaoTributariaICMS } from '@models';
import { OrigemMercadoria } from '../../../model/OrigemMercadoria';
import { TipoItensService } from '../../../services/tipo-itens.service';
import { AliquotasIcmsService } from '../../../services/aliquotasIcms.service';
import { AbrirModalComponent } from '../../../shared/abrir-modal/abrir-modal.component';
import { IAliquotaICMS } from '../../../model/IAliquotaICMS';
import { UnidadesEmpresaService } from '../../../services/unidades-empresa.service';

@Component({
  selector: 'app-alterar-dados-fiscais',
  templateUrl: './alterar-dados-fiscais.component.html',
  styleUrls: ['./alterar-dados-fiscais.component.css']
})
export class AlterarDadosFiscaisComponent extends ModalCadastroComponent implements OnInit {
  @ViewChild('modalCadastro', { static: true }) modalCadastro;
  @ViewChild('abrirModal', { static: false }) abrirModal: AbrirModalComponent;

  cstIPI = new TipoSituacaoTributariaIPI().Listar();
  cstPIS = new TipoSituacaoTributariaPIS().List();
  cstCOFINS = new TipoSituacaoTributariaCOFINS().Listar();
  origens = new OrigemMercadoria().ListarOrigem();
  cstICMS = null;
  tipoItens = null;
  aliquotasICMS: IAliquotaICMS[] = null;

  tipoItensIDsPermitidos: number[] = [1, 5, 9]; // [Mercadoria para Revenda, Produto Acabado, Ativo Imobilizado]
  tipoItensCodigosPermitidos: string[] = ['00', '04', '08']; // [Mercadoria para Revenda, Produto Acabado, Ativo Imobilizado]

  unidadeEmpresa = null;

  constructor(
    private itensService: ItensService,
    private codigosFiscaisService: CodigosFiscaisService,
    private tipoItensService: TipoItensService,
    private aliquotasICMSService: AliquotasIcmsService,
    private unidadesEmpresaService: UnidadesEmpresaService,
  ) {
    super();

    this.cadastroForm = new FormGroup({
      crt: new FormControl(null),
      modo: new FormControl(null),
      produtoID: new FormControl(null),
      cfopCodigo: new FormControl(null),
      cfopID: new FormControl(null),
      tipoItemID: new FormControl(null),
      situacaoTributariaICMS: new FormControl(null),
      situacaoTributariaIPI: new FormControl(null, [Validators.required]),
      situacaoTributariaPIS: new FormControl(null, [Validators.required]),
      situacaoTributariaCOFINS: new FormControl(null, [Validators.required]),
      aliquotaIPI: new FormControl(null),
      aliquotaPIS: new FormControl(null),
      aliquotaCOFINS: new FormControl(null),
      aliquotaICMSID: new FormControl(null),
      origemMercadoriaID: new FormControl(null),
      isentoIcms: new FormControl(false),
      substituicaoTributaria: new FormControl(false),
      abaterICMSBasePISCOFINS: new FormControl(false),
    });
  }

  ngOnInit() {
    this.loadComboboxData();
    this.cstICMS = ListaTipoSituacaoTributariaICMS;
    this.cadastroForm.patchValue(this.prop);

    if (this.prop.modo === 'cfop' && !this.cfopOriginadaEncomendaFutura) {
      this.cadastroForm.get('situacaoTributariaICMS').setValidators([Validators.required]);
      this.cadastroForm.get('origemMercadoriaID').setValidators([Validators.required]);
    }

    if (this.prop.modo === 'produto' && !['6554', '5554', '1554', '2554'].includes(this.prop.cfopCodigo)) {
      this.cadastroForm.get('tipoItemID').setValidators([Validators.required]);
      this.cadastroForm.get('aliquotaICMSID').setValidators([Validators.required]);
      this.cadastroForm.get('origemMercadoriaID').setValidators([Validators.required]);
    } else if (this.prop.modo === 'produto' && ['6554', '5554', '1554', '2554'].includes(this.prop.cfopCodigo)) {
      this.cadastroForm.get('tipoItemID').setValidators([Validators.required]);
      this.cadastroForm.get('situacaoTributariaIPI').clearValidators();
      this.cadastroForm.get('situacaoTributariaPIS').clearValidators();
      this.cadastroForm.get('situacaoTributariaCOFINS').clearValidators();
    }

    if (!this.tipoItensIDsPermitidos.includes(parseInt(this.prop.tipoItemID)))
      this.cadastroForm.get('tipoItemID').setValue(null);

    if (this.cadastroForm.get('abaterICMSBasePISCOFINS').value === null
      || this.cadastroForm.get('abaterICMSBasePISCOFINS').value === undefined) {
      this.cadastroForm.get('abaterICMSBasePISCOFINS').setValue(true);
    }

    if (this.cadastroForm.get('isentoIcms').value === null
      || this.cadastroForm.get('isentoIcms').value === undefined) {
      this.cadastroForm.get('isentoIcms').setValue(false);
    }

    if (this.cadastroForm.get('substituicaoTributaria').value === null
      || this.cadastroForm.get('substituicaoTributaria').value === undefined) {
      this.cadastroForm.get('substituicaoTributaria').setValue(false);
    }

    this.validaForm();
    this.loading = false;
  }

  submit() {
    this.loading = true;
    this.validaForm();
    if (!this.cadastroForm.valid) {
      this.loading = false;
      return;
    }

    const setLoadingFalse = () => { this.loading = false };

    if (this.cadastroForm.value.modo === 'produto') {
      this.itensService.alterarDadosFiscais(this.cadastroForm.getRawValue()).subscribe(_ => {
        this.closed.emit({ salvou: true, dados: this.cadastroForm.getRawValue() });
        setLoadingFalse();
      }, setLoadingFalse);
    } else {
      this.codigosFiscaisService.alterarDadosFiscais(this.cadastroForm.getRawValue()).subscribe(_ => {
        this.closed.emit({ salvou: true });
        setLoadingFalse();
      }, setLoadingFalse);
    }
  }

  get cfopOriginadaEncomendaFutura() {
    return ['5116', '5117', '6116', '6117'].includes(this.cadastroForm.get('cfopCodigo').value);
  }

  async loadComboboxData() {
    try {
      const [tipoItens, aliquotasICMS, unidadeEmpresa] = await Promise.all([
        this.tipoItensService.getMultiplosPorCodigo(this.tipoItensCodigosPermitidos).toPromise(),
        this.aliquotasICMSService.getAliquotasIcms().toPromise(),
        this.unidadesEmpresaService.getUnidadeEmpresa().toPromise()
      ]);
      this.tipoItens = tipoItens;
      this.aliquotasICMS = aliquotasICMS;
      this.unidadeEmpresa = unidadeEmpresa;
    } catch (error) {
      console.error(error)
    }
  }

  get estadoEmpresa() {
    return this.unidadeEmpresa ? this.unidadeEmpresa.cidade.estado.siglaEstado : null;
  }

  show() {
    this.modalCadastro.show();
  }

  destroyComponent() {
    this.closed.emit();
  }

  abrirCadastroAliquota(searchTerm: string) {
    this.abrirModal.cadastrar('aliquotaIcms', searchTerm);
    const sub = this.abrirModal.closed.subscribe(async (id: number) => {
      sub.unsubscribe();
      this.aliquotasICMS = await this.aliquotasICMSService.getAliquotasIcms().toPromise();
      this.cadastroForm.get('aliquotaICMSID').setValue(id);
    });
  }

  aliquotaIcmsSelectLabel(item: IAliquotaICMS) {
    if ((item.percentualRedutorICMS || 0) > 0)
      return `${item.aliquotaICMS}% - Redução ${item.percentualRedutorICMS || 0}%`;

    return `${item.aliquotaICMS}%`;
  }

  changedSubstituicaoTributaria(event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
    if (checked) {
      this.cadastroForm.get('isentoIcms').setValue(false);
    }
  }

  changedIsentoIcms(event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
    if (checked) {
      this.cadastroForm.get('substituicaoTributaria').setValue(false);
    }
  }

  async changedCstPisCofins(campo: 'aliquotaPIS' | 'aliquotaCOFINS', cst: { tipo: string }) {
    if (cst.tipo === '01' && this.unidadeEmpresa.crt >= 2 && !this.cadastroForm.get(campo).value) {
      try {
        this.loading = true;

        const aliquotas = await this.itensService.buscarAliquotasPISCOFINS().toPromise();
        if (aliquotas) {
          const valor = campo === 'aliquotaPIS' ? aliquotas.aliquotaPIS : aliquotas.aliquotaCOFINS;
          this.cadastroForm.get(campo).setValue(valor);
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    }
  }
}
