<app-temp-modal-cadastro #modalConsultar [textHeader]="'Usuários Empresa'" [customClass]="'modal-lg'" (closed)="onClose()">
  <div class="app-modal-body">
    <ng-container *ngIf="retornoUsuarios && retornoUsuarios.items">
      <app-dim-datatable [data]="retornoUsuarios.items" [columns]="colunasUsuarios" (sorting)="onSortUsuarios($event)"
        [rowsOnPage]="10" [totalItems]="retornoUsuarios.totalItems" (pageChanged)="setPageUsuarios($event)"
        (clickButton)="editarUsuario($event)" [showInfoMobile]="false" [lyneStyle]="verificaUserAdmin()">
      </app-dim-datatable>
    </ng-container>

    <div class="row" [formGroup]="formImportador">
      <div class="col form-group">
        <label class="control-label" for="usuarioImportador">Usuário Importador</label>
        <input class="form-control" name="usuarioImportador" type="text" formControlName="cnpj">
      </div>
      <div class="col form-group">
        <label class="control-label" for="senhaImportador">Senha Importador</label>
        <input class="form-control" name="senhaImportador" type="text" formControlName="senha" maxlength="20">
      </div>
      <div class="col form-group align-content-end" *ngIf="formImportador.get('cnpj').value">
        <button *ngIf="formImportador.get('senha').disabled" class="btn btn-primary" (click)="editarSenhaImportador()">
          <i class="ti ti-pencil"></i> Editar
        </button>
        <button *ngIf="!formImportador.get('senha').disabled" class="btn btn-primary" (click)="salvarSenhaImportador()" [disabled]="loading['salvarSenhaImportador']">
          <app-loading [loading]="loading['salvarSenhaImportador']" [icone]="'ti ti-save'" texto="Salvar"></app-loading>
        </button>
      </div>
    </div>

  </div>

  <div class="app-modal-footer">
  </div>
</app-temp-modal-cadastro>
<app-abrir-modal #abrirModal (closed)="doConsultaUsuariosEmpresa()">
</app-abrir-modal>