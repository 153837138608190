<app-temp-modal-cadastro #modalCadastro (closed)="onClose()" [textHeader]="textHeader">
  <form [formGroup]="cadastroForm" (ngSubmit)="submitForm()">

    <div class="row">
      <div class="form-group col-sm-12">
        <label class="label-required">Nome</label>
        <input appAutofocus [disableControl]="loading['registro']" type="text" class="form-control" formControlName="nome" />
        <show-errors [control]="cadastroForm.controls.nome"></show-errors>
      </div>
    </div>


    <div class="row">
      <div class="form-group col-sm-6">
        <label class="label-required">CPF/CNPJ</label>
        <input [mask]="retornaMascaraCpfCnpj()" (blur)="carregaInscricaoEstadual()"
          [disableControl]="loading['registro']" type="text" class="form-control" formControlName="cnpj" />
        <show-errors [control]="cadastroForm.controls.cnpj"></show-errors>
      </div>

      <div class="form-group col-sm-6">
        <label class="label-required">Insc. Est.</label>
        <input [disableControl]="loading['registro']" type="text" class="form-control"
          formControlName="inscricaoEstadual" />
        <show-errors [control]="cadastroForm.controls.inscricaoEstadual"></show-errors>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-6">
        <label>CEP</label>
        <input id="cep" type="text" (blur)="carregaViaCep()" class="form-control"
          [disableControl]="loading['registro'] || loading['endereco']" formControlName="cep" mask='00000-000'>
      </div>

      <div class="form-group col-sm-6">
        <label class="label-required">Bairro</label>
        <input [disableControl]="loading['registro'] || loading['endereco']" type="text" class="form-control"
          formControlName="bairro" />
        <show-errors [control]="cadastroForm.controls.bairro"></show-errors>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-8">
        <label class="label-required">Endereço</label>
        <input [disableControl]="loading['registro'] || loading['endereco']" type="text" class="form-control"
          formControlName="endereco" />
        <show-errors [control]="cadastroForm.controls.endereco"></show-errors>
      </div>

      <div class="form-group col-sm-4">
        <label class="label-required">Número</label>
        <input [disableControl]="loading['registro'] || loading['endereco']" digitOnly class="form-control"
          formControlName="numero" />
        <show-errors [control]="cadastroForm.controls.numero"></show-errors>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-6">
        <label class="label-required">Estado</label>
        <ng-select (ngModelChange)="alteraEstado($event)" appendTo=".modal-content"
          [disableControl]="loading['registro'] || loading['estados'] || loading['endereco']"
          [loading]="loading['estados']" placeholder="Selecione" [items]="estados" bindLabel="nome" bindValue="id"
          formControlName="estado">
        </ng-select>
        <show-errors [control]="cadastroForm.controls.estado"></show-errors>
      </div>

      <div class="form-group col-sm-6">
        <label class="label-required">Cidade</label>
        <ng-select appendTo=".modal-content"
          [disableControl]="loading['registro'] || loading['cidades'] || loading['endereco'] || loading['cidadeIbge']"
          [loading]="loading['cidades'] || loading['cidadeIbge']" placeholder="Selecione" [items]="cidades"
          bindLabel="nome" bindValue="id" formControlName="cidadeId">
        </ng-select>
        <show-errors [control]="cadastroForm.controls.cidadeId"></show-errors>
      </div>
    </div>

    <div class="row" *ngIf="tela === 'mdfe'">
      <div class="form-group col-sm-6">
        <label class="label-required">Apólice</label>
        <input [disableControl]="loading['registro']" type="text" class="form-control"
          formControlName="nApol" maxlength="20" />
        <show-errors [control]="cadastroForm.controls.nApol"></show-errors>
      </div>
    </div>

    <div class="alert alert-danger" *ngIf="errors">
      <span>{{ errors }}</span>
    </div>

    <button [disabled]='loading["registro"]' type="submit" (click)="validaForm()" class="btn btn-primary ripple light">
      <app-loading [texto]="'OK'" [loading]="loading['registro']"></app-loading>
    </button>
    <button [disabled]='loading["registro"]' type="button" class="btn btn-default ripple"
      (click)="modalCadastro.hide()">Cancelar</button>
  </form>
</app-temp-modal-cadastro>
