import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GrupoDespesasService } from '../../../services/grupo-despesas.service';
import { ContascorrentesService } from '../../../services/contascorrentes.service';
import { lastValueFrom } from 'rxjs';
import { markFormGroupTouched } from '@utils';
import { TitulosFornecedoresServiceV2 } from '../../../services-v2/titulos-fornecedores/titulos-fornecedores-v2.service';
import { NFEntradaService } from '../../../services/nfentrada.service';

@Component({
  selector: 'app-modal-lancar-titulos-fornecedores',
  templateUrl: './modal-lancar-titulos-fornecedores.component.html',
  styleUrls: ['./modal-lancar-titulos-fornecedores.component.css']
})
export class ModalLancarTituloFornecedorComponent extends ModalCadastroComponent implements OnInit {

  @ViewChild('modal', { static: true }) modal: any;

  form = new FormGroup({
    tituloFornecedorId: new FormControl(null),
    grupoDespesaId: new FormControl(null, [Validators.required]),
    contaPagadoraId: new FormControl(null, [Validators.required]),
    portadorId: new FormControl(null, [Validators.required]),
    centroCustoId: new FormControl(null, [Validators.required]),
  });
  gruposDespesas = [];
  contas = [];
  tituloFornecedorId: number;
  nfEntrada: any;

  constructor(
    private grupoDespesasService: GrupoDespesasService,
    private contasService: ContascorrentesService,
    private contasPagarService: TitulosFornecedoresServiceV2,
    private nfEntradaService: NFEntradaService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.tituloFornecedorId = this.prop.tituloFornecedorId;
    this.carregaDadosNFEntrada();
    this.carregaContasCorrentes();
    this.carregaGrupoDespesas();
  }

  handleOnClose(): void {
    this.closed.emit();
  }

  handleOnCancel(): void {
    this.hide();
  }

  async handleOnSave(): Promise<void> {
    markFormGroupTouched(this.form);

    if (this.form.invalid) {
      return;
    }

    try {
      this.loading['request'] = true;
      this.form.get('tituloFornecedorId')?.setValue(this.tituloFornecedorId);
      await this.contasPagarService.lancarContaPagarTitulosFornecedores(this.form.value);
      this.hide();
      this.handleOnClose();
    } catch (error) {
      console.error(error);
    } finally {
      this.loading['request'] = false;
    }

  }

  async carregaGrupoDespesas() {
    try {
      this.gruposDespesas = await lastValueFrom(this.grupoDespesasService.get());
    } catch (error) {
      console.error(error);
    }
  }

  async carregaContasCorrentes() {
    try {
      this.contas = await lastValueFrom(this.contasService.get());
    } catch (error) {
      console.error(error);
    }
  }

  async carregaDadosNFEntrada() {
    try {
      this.nfEntrada = await lastValueFrom(this.nfEntradaService.buscarDadosModalTitulosFornecedores(this.tituloFornecedorId));
    } catch (error) {
      console.error(error);
    }
  }

  show(): void {
    this.modal.show();
  }

  private hide(): void {
    this.modal.hide();
  }
}
