<app-modal-basic #modalRelatorio (closed)="destroyComponent()">
    <div class="app-modal-header">
        <h4 class="modal-title">Relatório de entradas e saídas de Itens</h4>
    </div>
    <div class="app-modal-body">
        <form [formGroup]="impressaoForm" (ngSubmit)="submitForm()">
          <div class="row">
            <div class="form-group col-sm-12">
              <label>Emissão</label>
              <div class="input-group">
                <app-data [calendarPosition]="'left'" formControlName="periodo1"></app-data>
                <span class="input-group-addon">até</span>
                <app-data formControlName="periodo2"></app-data>
              </div>
            </div>
          </div>
          <button type="submit" class="btn btn-primary ripple light">
            <i class="icofont icofont-print"> </i> Imprimir
          </button>
          <button type="button" class="btn btn-danger ripple" (click)="modalRelatorio.hide()"><i class="ti ti-exit"></i>
            Cancelar
          </button>
        </form>
      </div>
      <div class="app-modal-footer">
      </div>
</app-modal-basic>
