import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConsultaPaginadaViewModel } from '@models';

import { BaseServiceV2 } from '@servicesv2';
import { ConsultarPrevisaoFinanceiraPaginatedResponseViewModel, ConsultarPrevisaoFinanceiraPaginatedViewModel, ExportarPrevisaoFinanceiraViewModel } from 'models/financeiro/PrevisaoFinanceiraViewModel';
import {
  ConsultaTitulosFornecedoresViewModel,
  ConsultaTitulosFornecedoresResponseViewModel
} from 'models/titulos-fornecedores/ConsultaTitulosFornecedoresViewModel';

@Injectable()
export class TitulosFornecedoresServiceV2 extends BaseServiceV2 {

  constructor(http: HttpClient) {
    super('TitulosFornecedores', http);
  }

  buscar(payload: ConsultaTitulosFornecedoresViewModel) {
    return this.postAsPromise<ConsultaTitulosFornecedoresViewModel, ConsultaTitulosFornecedoresResponseViewModel>(payload, 'buscar');
  }

  estornar(tituloFornecedorId: number) {
    return this.putAsPromise<undefined, { id: number }>(undefined, `estornar/${tituloFornecedorId}`);
  }

  lancarContaPagarManifesto(form: any) {
    return this.postAsPromise<any, undefined>(form, 'lancarContaPagarManifesto');
  }

  lancarContaPagarTitulosFornecedores(form: any) {
    return this.postAsPromise<any, undefined>(form, 'lancarContaPagarTitulosFornecedores');
  }

  async previsaoFinanceiraPaginated(payload: ConsultarPrevisaoFinanceiraPaginatedViewModel) {
    return await this.postPaginatedAsPromise<ConsultarPrevisaoFinanceiraPaginatedViewModel, ConsultaPaginadaViewModel<ConsultarPrevisaoFinanceiraPaginatedResponseViewModel>>(payload, 'previsaoFinanceiraPaginated');
  }

  async imprimir(payload: ExportarPrevisaoFinanceiraViewModel) {
    return await this.downloadBlobAsPromise<ExportarPrevisaoFinanceiraViewModel>(payload, 'previsaoFinanceiraExport');
  }

}
