import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class CodigosBeneficiosFiscaisService extends BaseService {

  constructor(private http: HttpClient) { super() }

  getBeneficiosBySt(stVenda: string) {
    return this.http.get(this.UrlService + 'CodigosBeneficiosFiscais/GetCodigosByCst/' + stVenda)
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  getBeneficios() {
    return this.http.get(this.UrlService + 'CodigosBeneficiosFiscais')
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  getCreditoPresumido() {
    return this.http.get(this.UrlService + 'CodigosBeneficiosFiscais/ListaCreditoPresumido')
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }
}
